<div class="top-bar" [ngClass]="{'solid' :  bgStyle=='solid'}">
  <div class=' d-flex flex-column'>
    <div class="px-1 d-flex align-items-center justify-content-between top-bar-header">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <a class="link-icon toggle-menu">
            <i class="fa fa-bars toggle-menu"></i>
          </a>
          <a href="https://api.whatsapp.com/send?phone=974{{info?.supportPhone}}" class="link-icon px-2" target="_blank">
            <i class="fa fa-whatsapp"></i>
          </a>
        </div>
      </div>
      <img routerLink=".." *ngIf="!hideLogo" class="logo-small cursor-pointer" [src]="info?.logoPic"
        style="max-height: 45px;min-width: 45px; min-height: 45px;">
      <div class="d-flex align-items-center justify-content-center">
        <div class='px-2'>
        <app-cart-icon></app-cart-icon>
        </div>
        <a class="lang-switcher link-icon" (click)='toggleLang()'><i class="im im-ayin t-large"></i></a>
      </div>

    </div>
    <div class="top-menu color-white" *ngIf="expanded">
      <ul>
        <!-- <li class="my-2" *ngIf="!isLoggedIn()"><a routerLink='/register' class="color-white close-menu">{{'Register' | translate}}</a></li> -->
        <li class="my-2" *ngIf="!isLoggedIn()"><a [routerLink]='["/login"]' class="color-white close-menu">{{'Login / Register' | translate}}</a></li>
        <!-- <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/profile' class="color-white close-menu">{{'Account Info' | translate}}</a></li> -->
        <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/my-orders' class="color-white close-menu">{{'My Orders' | translate}}</a></li>
        <li class="my-2" ><a routerLink='/track-order' class="color-white close-menu">{{'Track Your Order' | translate}}</a></li>
        <li class="my-2"><a routerLink='/info' class="color-white close-menu">{{'Our Branches' | translate}}</a></li>
        <li class="my-2" *ngIf="isLoggedIn()"><a  class="color-white close-menu cursor-pointer" (click)="logout()">{{'Sign Out' | translate}}</a></li>
        <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/my-addresses' class="color-white close-menu">{{'Delivery Addresses' | translate}}</a></li>
      </ul>

    </div>
    <div class="py-2 d-flex align-items-center border-bottom color-primary" *ngIf="screenTitle">
      <div class="pt-2 cursor-pointer" (click)="goBack()">
        <i class="px-1 far fa-angle-left fa-2x"></i>
        <i class="px-1"></i>
      </div>
      <div class="pt-2 f-boldes t-large">
        {{screenTitle | translate}}
      </div>
    </div>
  </div>
</div>
