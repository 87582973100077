<div class="d-flex align-items-space-between inline-item-card">
  <div class="item-info d-flex flex-column align-items-between justify-content-between mx-3">
    <h3 routerLink='/item/{{item?.itemInfo.id}}'>{{item?.itemInfo.title | iknLocalized}}</h3>
    <div class="color-grey" routerLink='/item/{{item?.itemInfo.id}}'>{{item?.itemInfo.ingredients | iknLocalized}}</div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="font-bold mt-1 row" routerLink='/item/{{item?.itemInfo.id}}'>
        <div *ngIf="item?.itemInfo.priceBefore" class="px-1"> <p class="font-weight-bold mb-0 text-strike text-danger px-1" > {{item.itemInfo.priceBefore | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</p></div>
        <div *ngIf="item?.itemInfo.price">
          {{item.itemInfo.price | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}
        </div>
        <div *ngIf="!item?.itemInfo?.price" class='t-medium'>
          {{'Price on selection' | translate}}
        </div>
      </div>
      <div class="btn btn-outline-secondary p-0 px-2 position-relative"  (click)="addItem(item)">
        <span class="text-black">{{'Add To Cart' | translate}}</span> 
        <div class="add-to-cart-plus-icon">+</div>
      </div>
    </div>
  </div>
  <div class="item-pic" routerLink='/item/{{item?.itemInfo.id}}'>
    <img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt="">
  </div>
</div>
<hr class="m-2">
<div class="menu-card d-none">
  <a routerLink='/item/{{item?.itemInfo.id}}'>
    <div class="img"><img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
    <div class="card-title f-s-18 text-center">{{item?.itemInfo?.title | iknLocalized}}</div>
  </a>
  <div class="row-nums">
    <div class="desc text-danger out-stock-txt respect-dir" *ngIf="checkOutOfStock(item)">
      {{'Out of Stock'| translate}}
    </div>
    <div class="force-rtl" style="display:flex;" *ngIf="!checkOutOfStock(item)">
      <span class="bg-primary plus-icon" (click)="addItem(item)"><i class="im im-plus"></i></span>
      <span class="num-qty respect-dir">
        <span class="card-price font-bold">{{item.itemInfo.price | iknCurrency}}
          {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</span>
        <span class="card-qty">&nbsp;<i class="fa fa-times"></i> {{item.qty}}</span>
      </span>
      <span class="bg-primary minus-icon" (click)="removeItem(item)"><i class="im im-minus"></i></span>
    </div>
  </div>
</div>